import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Content from "../components/content"
import { VscTriangleLeft } from "react-icons/vsc";


const BlogPost = ({ location, data, pageContext }) => {
  const { page, locale } = pageContext

  return (
    <Layout locale={locale}>
      <section className="section">
        <div className="blogpost-link">
          <Link to={`/${page.locale}/aktuelles`}>
            <VscTriangleLeft size={15} />
            Zurück
          </Link>
        </div>

        <div className='container pb-5'>

          <div className="box">
            <h4>{page.Date}</h4><br/>
            <h2 className="title is-3">{page.Title}</h2>
            <Content locale={locale} markdown={page.Content} images={page.Images} />
          </div>
          
        </div>
      </section>
    </Layout>
  )
}

export default BlogPost;
